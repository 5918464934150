<template>
    <div class="home">
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="AI名称" label-width="100px">
                <el-input v-model="search.rolename"></el-input>
            </el-form-item>
            <el-form-item label="分类" label-width="100px">
                <el-select v-model="search.typeId" style="width: 100%">
                    <el-option v-for="item in typeList" :label='item.typename' :value='item.id' :key='item.id'></el-option>
                </el-select>
            </el-form-item>




            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <el-button size='medium' type='primary' @click="roleTypeFn()">创建AI分类</el-button>
        <el-button size='medium' type='primary' @click="addRole()">创建新AI</el-button>
        <div class="posr">
            <el-table ref="multipleTableRef" v-loading="loadingShow" :data="tableData" style="width: 100%;margin-top: 20px;"
                :header-cell-style="{ background: '#F7F8FA', color: '#333333' }">
                <el-table-column type="index" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="rolename" label="AI名称" align="center">
                </el-table-column>
                <el-table-column prop="intro" label="AI简介" align="center">
                </el-table-column>
                <el-table-column prop="rolotype" label="AI分类" align="center">
                </el-table-column>
                <el-table-column prop="headphoto" label="AI头像" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.headphoto" style="width: 50px;height:50px;" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="preposition" label="前置语" align="center">
                </el-table-column>
                <el-table-column prop="createtime" label="新增时间" align="center">
                </el-table-column>

                <el-table-column align="center" fixed="right" width="160px" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="addRole(scope.row)">修改</el-button>
                        <el-button type="text" @click="FriBanWordDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer-pagin">
                <el-pagination :current-page.sync="search.page" :page-sizes="pageSizes" :pager-count="5"
                    :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>

        <el-dialog :title="id ? '修改' : '新增'" :visible.sync="roleTypeVisible" @close="closeDialog" width="40%">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="分类名称" prop="typename">
                        <el-input v-model.trim="ruleForm.typename"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="roleTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm',0)">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="id ? '修改' : '新增'" :visible.sync="examineDialog" @close="closeDialog" :close-on-click-modal="false" width="600px">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="AI名称" prop="rolename">
                        <el-input v-model.trim="ruleForm.rolename"></el-input>
                    </el-form-item>
                    <el-form-item label="AI分类" prop="typeid">
                        <el-select v-model="ruleForm.typeid" style="width: 100%">
                            <el-option v-for="item in typeList" :label='item.typename' :value='item.id' :key='item.id'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="AI简介" prop="intro">
                        <el-input v-model.trim="ruleForm.intro" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="AI前置语" prop="preposition">
                        <el-input v-model.trim="ruleForm.preposition" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="AI头像" prop="fileList">
                        <element-upload
                            ref="upload"
                            :action="baseUrl"
                            list-type="picture-card"
                            accept=".png,.jpg"
                            :headers="token"
                            :data="dataObj"
                            :file-list="fileList"
                            :limit="1"
                            :before-upload="beforeUpload"
                            :on-error="uploadError"
                            :on-success="uploadSuccess"
                            :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                        </element-upload>
                    </el-form-item>
                    <el-form-item label="设置为官方百科" prop="issys">
                        <el-radio-group v-model="ruleForm.issys">
                            <el-radio :label="0">否</el-radio>
                            <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="AI欢迎语" prop="welcome">
                        <el-input v-model.trim="ruleForm.welcome" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="AI推荐问题">
                        <el-form-item label-width="80px" :label="'问题'+(index+1)" :prop="'questions.' + index + '.value'" :rules="rules.value" v-for="(item,index) in ruleForm.questions" :key="index" class="questions-box">
                            <i class="el-icon-delete" @click="ruleForm.questions.splice(index,1)"></i>
                            <el-input v-model.trim="item.value" type="textarea"></el-input>
                        </el-form-item>
                        <el-button type="text" @click="ruleForm.questions.push({value: ''})">添加推荐问题</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examineDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm',1)">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
  
<script>
import ElementUpload from '@/components/ElementUpload/index.vue'
import { GetPage,GetRoleTypes,AddRoleType, AddRole, UpdateRole, DelRole } from '@/service/role/role.js'
// @ is an alias to /src
import {
    MessageBox, Message
} from 'element-ui'
import { base } from '@/service/api.js'
export default {
    name: 'Home',
    components: {
        ElementUpload
    },
    data() {
        var fileListArr = (rule, value, callback) => {
            if (this.fileList.length === 0) {
                return callback(new Error("请上传图片"));
            } else {
                return callback();
            }
        };
        return {
            loadingShow: false,
            roleTypeVisible: false,
            ruleForm: {
                questions: [{value: ''}]
            },
            rules: {
                typename: [
                    { required: true, message: '请填写分类名称', trigger: 'blur' },
                ],
                rolename: [
                    { required: true, message: '请输入AI名称', trigger: 'blur' },
                ],
                typeid: [
                    { required: true, message: '请选择AI分类', trigger: 'change' },
                ],
                intro: [
                    { required: true, message: '请输入AI简介', trigger: 'blur' },
                ],
                preposition: [
                    { required: true, message: '请输入AI前置语', trigger: 'blur' },
                ],
                fileList: [
                    { validator: fileListArr, trigger: 'blur' }
				],
                issys: [
                    { required: true, message: '是否设置为官方百科', trigger: 'change' },
                ],
                welcome: [
                    { required: true, message: '请输入AI欢迎语', trigger: 'blur' },
                ],
                value: [
                    { required: true, message: '请输入问题', trigger: 'blur' },
                ],
            },
            id: '',
            tableData: [],
            search: {
                page: 1,
                pageSize: 10,
            },
            pageSizes: [10, 20, 50, 100],
            total: 0,
            examineDialog: false,
            fileList: [],
            baseUrl: base + '/File/UploadPhoto',
            token: {
                Authorization: 'bearer ' + localStorage.getItem('accesstoken'),
                'X-Authorization': 'bearer ' + localStorage.getItem('refreshtoken')
            },
            dataObj: {
				tag: 'product'
			},
            typeList: []
        }
    },
    created() {
        this.GetRoleTypes()
        this.gettableList();
    },
    methods: {
        GetRoleTypes(){
            GetRoleTypes().then(res => {
                this.typeList = res.data
            }).catch(err => {
                console.log(err)
            })
        },
        
        roleTypeFn(row) {
            this.id = row ? row.id : ''
            if (row) {
                this.$set(this.ruleForm, 'word', row.word)
                this.$set(this.ruleForm, 'category', row.category)
                this.$set(this.ruleForm, 'id', row.id)
            }
            this.roleTypeVisible = true
        },
        addRole(row) {
            this.id = row ? row.id : ''
            this.ruleForm
            if (row) {
                this.$set(this.ruleForm, 'id', row.id)
                this.$set(this, 'fileList', [{url: row.headphoto}])
                this.$set(this.ruleForm, 'intro', row.intro)
                this.$set(this.ruleForm, 'preposition', row.preposition)
                this.$set(this.ruleForm, 'rolename', row.rolename)
                this.$set(this.ruleForm, 'rolotype', row.rolotype)
                this.$set(this.ruleForm, 'typeid', row.typeid)
                this.$set(this.ruleForm, 'welcome', row.welcome)
                this.$set(this.ruleForm, 'issys', row.issys)
                row.questions = row.questions || []
                let questions = []
                row.questions.map(item=>{
                    questions.push({value: item})
                })
                this.$set(this.ruleForm, 'questions', questions)
            }
            
            this.examineDialog = true
        },
        submitExamine() {
            let file = this.$refs.upload.uploadFiles[0]
            if (!file) {
                Message.error('请上传文件')
                return false
            }
            this.$refs.upload.submit()
        },
        handleRemove(file, fileList) {
            console.log('移除', file, fileList)
            console.log(this.$refs.upload.clearFiles())
            this.fileList = fileList
        },
        uploadSuccess(file) {
			console.log('成功',file)
			if(file.statuscode===200){
				this.fileList.push({
					url: file.data.url,
				})
			}
        },
        beforeUpload(file) {
        // console.log(file)
        if (file.size > 1 * 1024 * 1024) {
                        Message.error(`请上传小于1M图片`)
            return false;
        }
        return true;
        },
        uploadError(err){
            console.log('失败1',err.response)	
            console.log('失败2',err.data)
            console.log('失败3',err.headers)
            console.log('失败4',err.headers['access-token'])
        },
        beforeAvatarUpload(file) {
            console.log(file)
        },
        preview() {
            console.log('图片预览')
        },
        // 上传失败
        errorUpload() {
            Message.error('上传失败')
        },
        closeDialog() {
            this.ruleForm = {questions: [{value: ''}]}
            this.fileList = []
        },
        submitForm(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                if (this.id) {
                    let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                    ruleForm.headPhoto = this.fileList[0].url
                    let questions = []
                    ruleForm.questions.map(item=>{
                        questions.push(item.value)
                    })
                    ruleForm.questions = questions
                    UpdateRole({ ...ruleForm }).then(res => {
                        this.loadingShow = false;
                        console.log(res)
                        this.examineDialog = false
                        Message.success(`修改成功`)
                        this.gettableList()
                    }).catch(err => {
                        console.log(err)
                        this.loadingShow = false;
                    })
                } else {
                    if(type == 0){
                        AddRoleType({ ...this.ruleForm }).then(res => {
                            this.loadingShow = false;
                            console.log(res)
                            this.roleTypeVisible = false
                            Message.success(`新增成功`)
                            this.GetRoleTypes()
                        }).catch(err => {
                            console.log(err)
                            this.loadingShow = false;
                        })
                    } else {
                        let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                        ruleForm.headPhoto = this.fileList[0].url
                        let questions = []
                        ruleForm.questions.map(item=>{
                            questions.push(item.value)
                        })
                        ruleForm.questions = questions
                        AddRole({ ...ruleForm }).then(res => {
                            this.loadingShow = false;
                            console.log(res)
                            this.examineDialog = false
                            Message.success(`新增成功`)
                            this.gettableList()
                        }).catch(err => {
                            console.log(err)
                            this.loadingShow = false;
                        })
                    }
                }

            });
        },
        serchFn() {
            this.gettableList();
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            this.search.page = 1
            this.search.pageSize = 10
            this.serchFn()
        },
        gettableList() {
            this.loadingShow = true
            const query = {
                ...this.search
            }

            GetPage(query).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.tableData = res.data.list
                this.total = res.data.count
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        // 每页的条数
        handleSizeChange(val) {
            this.search.pageSize = val;
            this.gettableList();
        },
        // 分页
        handleCurrentChange(val) {
            this.search.page = val;
            this.gettableList();
        },
        FriBanWordDel(row) {
            MessageBox.confirm('请确认是否删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingShow = true
                DelRole({
                    id: row.id,
                }).then(res => {
                    console.log(res)
                    this.loadingShow = false
                    Message.success(`删除成功`)
                    this.gettableList();
                }).catch(err => {
                    this.loadingShow = false
                    console.log(err)
                })
            }).catch(() => {
                Message.info('取消删除!')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.questions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  